import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/sign-in",
    component: () => import("@/layout/BrokerLayout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/brokerage/Dashboard.vue"),
      },
      {
        path: "/cashbook",
        name: "cashbook",
        component: () => import("@/views/brokerage/transactions/Cashbook.vue"),
      },
      {
        path: "/cashbook-update",
        name: "cashbookUpdate",
        props: true,
        component: () => import("@/views/brokerage/transactions/CashbookUpdate.vue"),
      },
      {
        path: "/invoice-update",
        name: "invoiceUpdate",
        props: true,
        component: () => import("@/views/brokerage/transactions/InvoiceUpdate.vue"),
      },
      {
        path: "/invoice",
        name: "invoice",
        component: () => import("@/views/brokerage/transactions/Invoice.vue"),
      },
      {
        path: "/invoice/print",
        name: "PrintInvoice",
        component: () => import("@/views/brokerage/transactions/PrintInvoice.vue"),
      },

      // {
      //   path: "/paid-to-party",
      //   name: "PaidToParty",
      //   component: () => import("@/views/brokerage/transactions/Paid.vue"),
      // },
      // {
      //   path: "/recieve-from-party",
      //   name: "RecieveFromParty",
      //   component: () => import("@/views/brokerage/transactions/Recieve.vue"),
      // },
      {
        path: "/cashbook-listing",
        name: "CashbookListing",
        component: () => import("@/views/brokerage/transactions/CashbookListing.vue"),
      },
      {
        path: "/invoice-listing",
        name: "InvoiceListing",
        component: () => import("@/views/brokerage/transactions/InvoiceListing.vue"),
      },
      {
        path: "/profit-report",
        name: "ProfitReport",
        component: () => import("@/views/brokerage/reports/ProfitReport.vue"),
      },
      {
        path: "/company-ledger",
        name: "Ledger",
        component: () => import("@/views/brokerage/reports/CompanyLedger.vue"),
      },
      
      {
        path: "/purchases",
        name: "purchases",
        component: () => import("@/views/brokerage/Purchases.vue"),
      },
      {
        path: "/invoices",
        name: "invoices",
        component: () => import("@/views/apps/Invoices.vue"), 
      },
      {
        path: "/customers",
        name: "customers",
        component: () => import("@/views/brokerage/reports/CustomerListing.vue"), 
      },
      {
        path: "/customer/details",
        name: "clientDetail",
        component: () => import("@/views/apps/ClientDetail.vue"),
      
      },
      {
        path: "/widget",
        name: "widget",
        component: () => import("@/components/customers/cards/statments/Statement.vue"),
      },
      
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/brokerage/Users/Users.vue"),
      
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/views/apps/UserProfile.vue"),
      
      },
      {
        path: "/clients",
        name: "clients",
        component: () => import("@/views/apps/ClientListing.vue"),
      
      },
      {
        path: "/clientDetail",
        name: "clientDetails  ",
        component: () => import("@/views/apps/ClientDetail.vue"),
      
      },
      {
        path: "/learningzone",
        name: "learningzone",
        component: () => import("@/views/apps/LearningZone.vue"),
        
      },
      {
        path: "/learningzone/create/:id?",
        name: "learningzone-create",
        props: true,
        component: () => import("@/views/apps/CreateLearningZone.vue"),
        
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/apps/Settings.vue"),
        
      },

        ],
      },
  {
  path: "/",
  component: () => import("@/layout/SupplierLayout.vue"),
  children: [
    {
      path: "/welcome",
      name: "welcome",
      component: () => import("@/views/Welcome.vue"),
      children: [
        {
        path: "/dash",
        name: "dash",
        component: () => import("@/views/brokerage/Dashboard.vue")
      },
      ],
    },
    {
      path: "/learnings",
      name: "learnings",
      component: () => import("@/views/Learnings.vue"),
    },
    {
      path: "/funnel",
      name: "funnel",
      component: () => import("@/views/apps/Funnel.vue")
    },
    {
      path: "/webpage",
      name: "webpage",
      component: () => import("@/views/apps/Webpage.vue")
    },
    {
      path: "/funnel-learning-zone",
      name: "funnel-learning-zone",
      component: () => import("@/views/apps/FunnelsLearningZone.vue")
    },
    {
      path: "/webpage-learning-zone",
      name: "webpage-learning-zone",
      component: () => import("@/views/apps/WebpageLearningZone.vue")
    },

    {
      path: "/tracking",
      name: "tracking",
      component: () => import("@/views/apps/Tracking.vue")
    },
    {
      path: "/supportCalls",
      name: "Support Calls",
      component: () => import("@/views/SupportCalls.vue")
    },
  ]
    },
    {
      path: "/",
      component: () => import("@/layout/CustomerLayout.vue"),
      children: [
        {
          path: "/welcome",
          name: "welcome",
          component: () => import("@/views/Welcome.vue"),
          children: [
            {
            path: "/dash",
            name: "dash",
            component: () => import("@/views/brokerage/Dashboard.vue")
          },
          ],
        },
        {
          path: "/learnings",
          name: "learnings",
          component: () => import("@/views/Learnings.vue"),
        },
        {
          path: "/funnel",
          name: "funnel",
          component: () => import("@/views/apps/Funnel.vue")
        },
        {
          path: "/webpage",
          name: "webpage",
          component: () => import("@/views/apps/Webpage.vue")
        },
        {
          path: "/funnel-learning-zone",
          name: "funnel-learning-zone",
          component: () => import("@/views/apps/FunnelsLearningZone.vue")
        },
        {
          path: "/webpage-learning-zone",
          name: "webpage-learning-zone",
          component: () => import("@/views/apps/WebpageLearningZone.vue")
        },
    
        {
          path: "/tracking",
          name: "tracking",
          component: () => import("@/views/apps/Tracking.vue")
        },
        {
          path: "/supportCalls",
          name: "Support Calls",
          component: () => import("@/views/SupportCalls.vue")
        },
      ]
        },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/terms",
        name: "terms",
        component: () =>
          import("@/views/terms.vue"),
      },

    ],
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
const restrictedRoutes = ['user','groups']
// const userType = store.getters.currentUser.user_type
router.beforeEach((to,from ,next) => {
  let clientRedirect = '/welcome';
  let clientRedirectName = 'welcome';
  // if(store.getters.currentUser.is_form_enabled == "no"){
  //   clientRedirect = '/learnings';
  //   clientRedirectName = 'learnings';
  // }
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  
  if (to.name === 'sign-in' && JwtService.getToken()) {
    // if(store.getters.currentUser.role_id==2){
    //   next({ path: clientRedirect });
    // }else {
      next({ path: '/dashboard' });
    // }
  }
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });
  // if(to.name ==='dashboard' && JwtService.getToken() && store.getters.currentUser.role_id==2){
  //   router.push({
  //     name: clientRedirectName
  //   })
  // }
  if(to.name === clientRedirectName && JwtService.getToken()){
      router.push({
        name: 'dashboard'
      })
  }
  if(to.name == 'users' || to.name == 'clients' || to.name == 'settings' || to.name == 'user' || to.name == 'clientDetail'){
    if(store.getters.currentUser.role_id==1){
      next();
    }else {
      next({ path: '/dashboard' });
    }
  }else{
    next()
  }
  
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
